import { BubbleChat } from 'flowise-embed-react';
import logo from '../../assets/img/InkastleLogo.png';

export function ChatBot() {
  return (
    <BubbleChat
      chatflowid="3c98d4e2-a772-42da-b3ef-9525b5784db3"
        apiHost="https://flowise-1-g0ci.onrender.com"
      theme={{    
        button: {
          backgroundColor: 'orange',
          right: 23,
          bottom: 90,
          size: 48,
          dragAndDrop: true,
          iconColor: 'white',
          customIconSrc: logo,
          autoWindowOpen: {
            autoOpen: true,
            openDelay: 4,
            autoOpenOnMobile: false
          }
        },
        // tooltip: {
        //   showTooltip: true,
        //   tooltipMessage: 'Hi There 👋!',
        //   tooltipBackgroundColor: 'black',
        //   tooltipTextColor: 'white',
        //   tooltipFontSize: 16
        // },
        // disclaimer: {
        //   title: 'Disclaimer',
        //   message: "By using this chatbot, you agree to the <a target=\"_blank\" href=\"https://flowiseai.com/terms\">Terms & Condition</a>",
        //   textColor: 'black',
        //   buttonColor: '#3b82f6',
        //   buttonText: 'Start Chatting',
        //   buttonTextColor: 'white',
        //   blurredBackgroundColor: 'rgba(0, 0, 0, 0.4)',
        //   backgroundColor: 'white'
        // },
        chatWindow: {
          showTitle: true,
          showAgentMessages: true,
          title: 'Inkastle Studio',
          titleAvatarSrc: logo,
          welcomeMessage: 'Welcome to Inkastle Studio! How can I help you today?',
          errorMessage: 'This is a custom error message',
          backgroundColor: 'darkgray',
          height: 700,
          width: 400,
          fontSize: 16,
          starterPrompts: [
           "What games do you have?"
          ],
          starterPromptFontSize: 15,
          clearChatOnReload: true,
          sourceDocsTitle: 'Sources:',
          renderHTML: true,
          botMessage: {
            backgroundColor: '#f7f8ff',
            textColor: '#303235',
            showAvatar: true,
            avatarSrc: logo
          },
          userMessage: {
            backgroundColor: '#3B81F6',
            textColor: '#ffffff',
            showAvatar: true,
            avatarSrc: 'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png'
          },
          textInput: {
            placeholder: 'Type your question',
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: '#3B81F6',
            maxChars: 50,
            maxCharsWarningMessage: 'You exceeded the characters limit. Please input less than 50 characters.',
            autoFocus: true,
            sendMessageSound: false,
            receiveMessageSound: false,
          },
          feedback: {
            color: '#303235'
          },
          dateTimeToggle: {
            date: true,
            time: true
          },
          footer: {
            textColor: '#303235',
            text: 'Powered by',
            company: 'SofiBul AI',
            companyLink: 'https://sofibul.ai'
          }
        }
      }}
    />
  );
} 