import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
} from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ReactGA from "react-ga4";
import axios from "axios";
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import Careers from "./pages/Careers";
import Contact from "./pages/Contact";
import Root from "./pages/Root";
import CareersSubmited from "./pages/CareersSubmited";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
// import GDPRConsent from "./components/GDPRConsent/GDPRConsent";

import ScrollToTop from "./components/Helpers/ScrollToTop";

import { config } from "../src/common/Constants";
import { ChatBot } from "./components/ChatBot/ChatBot";

const apiUrl = config.API_URL;

ReactGA.initialize(config.analytics);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      { path: "", element: <Home /> },
      {
        path: "portfolio",
        element: <Portfolio />,
        loader: async () => {
          try {
            const response = await axios.get(`${apiUrl}/portfolio`);
            const data = response.data;
            return { data };
          } catch (error) {}
        },
      },
      {
        path: "careers",
        element: <Careers />,
        loader: async () => {
          try {
            const response = await axios.get(`${apiUrl}/careers`);
            const data = response.data;
            return { data };
          } catch (error) {}
        },
      },
      { path: "contact", element: <Contact /> },
      { path: "careers_submitted", element: <CareersSubmited /> },
      { path: "terms_and_conditions", element: <TermsAndConditions /> },
      { path: "privacy_policy", element: <PrivacyPolicy /> },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
]);

const App = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={config.recaptcha}>
      <RouterProvider router={router}>
        <ScrollToTop />
      </RouterProvider>
      <ChatBot />
    </GoogleReCaptchaProvider>
  );
};

export default App;